@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  padding: 0;
  margin: 0;
  font-family: 'Poppins',sans-serif;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  margin: 0px !important;
  padding: 0;
  font-family: 'Montserrat',sans-serif;
}

::-webkit-scrollbar{
  width: 8px;
  height: 80px;
}

::-webkit-scrollbar-track{
  background: #ffffff;
}

::-webkit-scrollbar-thumb{
  background: #6f6f6f;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover{
  background: #333333;
}